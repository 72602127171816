<template>
  <div style="margin: 10px auto;width: 1200px;">
    <div class="search-box">
      <div class="search-list">
        <div class="title">书籍分类：</div>
        <div class="tiaojian">
          <span :class="searchData.type==0?'select':''" @click="searchData.type=0">全部</span>
          <span v-for="(item) in cateList" :key="item.id" :class="searchData.type==item.id?'select':''"
                @click="searchData.type=item.id">{{
              item.title
            }}</span>
        </div>
      </div>
      <div class="search-list">
        <div class="title">书籍作者：</div>
        <div class="tiaojian">
          <span :class="searchData.author==0?'select':''" @click="searchData.author=0">全部</span>
          <span v-for="item in authorList" :key="item.id" :class="searchData.author==item.id?'select':''"
                @click="searchData.author=item.id">{{
              item.name
            }}</span>

        </div>
      </div>
      <div class="search-list">
        <div class="title">出版社：</div>
        <div class="tiaojian">
          <span :class="searchData.publisher==0?'select':''" @click="searchData.publisher=0">全部</span>
          <span v-for="item in publisherList" :key="item.id" :class="searchData.publisher==item.id?'select':''"
                @click="searchData.publisher=item.id">{{ item.name }}</span>
        </div>
      </div>
      <div class="search-list" style="display: none">
        <div class="title">发布日期：</div>
        <div class="tiaojian">
          <span :class="searchData.years==0?'select':''" @click="searchData.years=0">全部</span>
          <span v-for="item in yearList" :key="item.id" :class="searchData.years==item.id?'select':''"
                @click="searchData.years=item.id">{{ item.name }}年</span>
        </div>
      </div>
      <!--      <div class="search-list">-->
      <!--        <div class="title">书籍类型：</div>-->
      <!--        <div class="tiaojian">-->
      <!--          <span :class="searchData.leixing==0?'select':''" @click="searchData.leixing=0">全部</span>-->
      <!--          <span :class="searchData.leixing==1?'select':''" @click="searchData.leixing=1">PDF</span>-->
      <!--          <span :class="searchData.leixing==2?'select':''" @click="searchData.leixing=2">TXT</span>-->
      <!--          <span :class="searchData.leixing==3?'select':''" @click="searchData.leixing=3">DOC</span>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <el-divider></el-divider>
    <h1><i class="el-icon-s-management" style="color:rgb(48, 176, 143)"></i>电子教材库</h1>
    <div class="cai-ni">
      <div class="cai-ni-box" v-for="(o, index) in booksRecList" :key="index">
        <div class="book-info" @click="openBookInfo(o.bookId)">
          <el-image
              style="width: 150px;height:210px;border-radius: 4px"
              fit="cover"
              :src="global.apiUri+o.cover"></el-image>
          <div class="right">
            <div class="right-top">
              <h3 class="title">{{ o.title }}</h3>
              <p>{{ o.author }} 著</p>
              <p>分类：{{ o.cate }}</p>
              <p> 发布时间：{{ o.date }}</p>
            </div>
            <el-button type="text">立即阅读</el-button>
          </div>
        </div>
      </div>
    </div>
    <my-pagination v-show="booksRecList.length>0" ref="pagination" :currentPage="currentPage" :pageSize="pagesize"
                   :dataUrl="dataUrl"
                   :searchData="searchData" @getData="getData"></my-pagination>

    <el-empty v-show="booksRecList.length==0" description="暂无书籍"></el-empty>
  </div>
</template>

<script>
import MyPagination from "../../components/pagination";

export default {
  name: "hot",

  components: {MyPagination},
  data() {
    return {

      cateList: [],
      authorList: [],
      publisherList: [],
      yearList: [
        {id: 2023, name: 2023},
        {id: 2022, name: 2022},
        {id: 2021, name: 2021},
        {id: 2020, name: 2020},
        {id: 2019, name: 2019},
        {id: 2018, name: 2018},
        {id: 2017, name: 2017},
      ],
      booksRecList: [],
      currentPage: 1,
      pagesize: 10,
      dataUrl: '/ebook/book_list',
      searchData: {
        type: 0,
        years: 0,
        publisher: 0,
        author: 0,
        leixing: 0,
      },
    }
  },
  created() {
    this.getCate();
    this.getAuthor();
    this.getPublisher();
  },
  watch: {
    'searchData.type'() {
      this.searchSubmit();
    },
    'searchData.publisher'() {
      this.searchSubmit();
    },
    'searchData.author'() {
      this.searchSubmit();
    },
    'searchData.years'() {
      this.searchSubmit();
    },
  },
  methods: {
    getCate() {
      let _this = this;
      _this.axios.get('/common/cate')
          .then(function (res) {
            _this.cateList = res;
          });
    },
    getAuthor() {
      let _this = this;
      _this.axios.get('/common/author')
          .then(function (res) {
            _this.authorList = res;
          });
    },
    getPublisher() {
      let _this = this;
      _this.axios.get('/common/publisher')
          .then(function (res) {
            _this.publisherList = res;
          });
    },
    openBookInfo(bookId) {
      this.$router.push({path: '/book/bookInfo', query: {bookId: bookId}});
    },
    //查询
    searchSubmit() {
      this.$refs.pagination.pages = 1;
      this.currentPage = 1;
      setTimeout(() => {
        //异步执行列表刷新
        this.$refs.pagination.getTableData();
      }, 0)
    },
    //表格获取数据
    getData(e) {
      this.booksRecList = e;
    },
    //更新表格数据
    updateList() {
      this.$refs.pagination.getTableData();
    },
  },
}
</script>

<style scoped lang="less">
.search-box {
  display: flex;
  flex-direction: column;

  .search-list {
    display: flex;
    flex-direction: row;
    line-height: 30px;
    font-size: 14px;
    margin-bottom: 10px;

    .title {
      width: 100px;
      text-align: right;
      padding-right: 10px;
      box-sizing: border-box;
      font-weight: 600;
    }

    .tiaojian {
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      span {
        margin-right: 20px;
        cursor: pointer;
        padding: 0 10px 0 10px;
        box-sizing: border-box;
        background-color: #ffffff;
        border: 1px solid #ffffff;
      }

      .select {
        background-color: #ecf5ff;
        border: 1px solid #d9ecff;
        color: #409eff;
        border-radius: 5px;

      }
    }
  }
}

.home {
  width: 100%;
}

h1 {
  font-size: 18px;
}

.cai-ni {
  width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .cai-ni-box {
    width: 400px;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;

    .book-info {
      width: 380px;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      height: 210px;
      border-radius: 5px;
      border: 1px solid #E3DEDE74;
      box-sizing: border-box;
      font-size: 14px;
      cursor: pointer;

      .right {
        width: 230px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        box-sizing: border-box;

        .right-top {
          width: 100%;
        }

        .title {
          font-size: 16px;
          font-weight: bold;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //用省略号显示
          white-space: nowrap; //不换行
        }
      }
    }
  }
}
</style>